<template>
  <div class="about-main root">
    <!-- <div class="row"> --> 
      <!-- <el-drawer :visible.sync="drawer" :size="'240px'" :direction="'ltr'"> -->
        <div class="w-left">
          <ul>
            <li class="silder-title">
              My Daollar
            </li>
            <li>
              <router-link
                to="/adminview/orders"
                :class="{ active: $route.path === '/adminview/orders' }"
                >DO</router-link
              >
            </li>
          </ul>
        </div>
      <!-- </el-drawer> -->
      <div class="w-right">
        <!-- <div @click="drawer = !drawer">Menu</div> -->
        <router-view />
      </div>
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: true
    }
  },
}
</script>
<style scoped lang="less">
/deep/ .el-drawer {
  background: #1C70BD;
}
/deep/ .el-icon-close {
  color: #fff;
}
.about-main {
    background-color: black;
    display: flex;
    padding-top: 120px;
    overflow: hidden;
    min-height: 800px;
  ul {
    li {
      list-style: none;
      height: 40px;
      a {
        padding-left: 80px;
        font-size: 16px;
        line-height: 40px;
        color: white;
        font-weight: bold;
        display: block;
        width: 100%
      }
      a:hover {
       background-color: #085da3;
      }
      .active {
        background-color: #085da3;
      }
    }
  }
  .w-left {
    padding-top: 30px;
    flex: none;
    // width: 30%;
    width: 240px;
    background: #1C70BD;
    &>ul {
      margin-bottom: 0px;
    }
    .silder-title {
      /* My Daollar */
      font-family: ParmaPetit;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 35px;
      /* identical to box height */
      padding-left: 80px;
      color: #FFFFFF;
      // margin-bottom: 10px;
    }
  }
  .w-right { 
    padding: 30px 25px;
    background: #fff;
    word-wrap: break-word;
    width: 70%;
    flex: 1;
  }
}
@media (min-width: 2000px) {
  .about-main{
    min-height: 1400px;
  }
}
@media (max-width: 1199px) {
 .about-main {
   min-height: 1400px;
  background-color: black;
  min-width: 600px;
  ul {
    li {
      list-style: none;
      height: 40px;
      a {
        padding-left: 25px;
      }
    }
  }
  .w-left .silder-title {
    padding-left: 25px;
  }
}
}
@media (max-width: 968px) {
  .about-main {
    min-height: 1400px;
    background-color: black;
    min-width: 100%;
  }
}
@media (max-width: 768px) {
  .about-main {
    flex-direction: column;
    .w-left {
      width: auto;
    }
    .w-right {
      width: auto;
    }
  }
}
</style>
